import React, { useState, useEffect } from 'react';
import { FaLink, FaFilePdf, FaFileImage, FaFilePowerpoint, FaFileVideo, FaImage, FaAward } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import WorksMarkdown from '../markdowns/Works.md';

import WorksJSON from '../assets/json/works.json';

const WorkIcon = ({ label }) => {
	switch (label) {
		case 'Publication':
			return <FaLink />;

		case 'PDF':
			return <FaFilePdf />;

		case 'Poster':
			return <FaImage />;

		case 'Presentation':
			return <FaFilePowerpoint />;

		case 'Video':
			return <FaFileVideo />;

		case 'Image':
			return <FaFileImage />;

		default:
			return null;
	}
};

const WorkLinks = ({ links }) => {
	if (links.length === 0) return null;

	return <div className="work__links">{links}</div>;
};

const WorkLink = ({ label, uri }) => (
	<a href={uri} rel="noopener noreferrer" target="_blank">
		<WorkIcon label={label} /> {label}
	</a>
);

const WorkDescription = ({ description }) => {
	if (!description) return null;

	return <div className="work__description">{description}</div>;
};

const WorkAward = ({ award }) => {
	if (!award) return null;

	return (
		<div className="work__award">
			<FaAward /> {award}
		</div>
	);
};

const Work = ({ code, title, award, description, links = [] }) => {
	let workLinks = links.map((link) => (
		<WorkLink label={link.label} uri={link.uri} key={code + link.label + link.uri} />
	));

	return (
		<div className="work">
			<div className="work__title">
				<span className="work__code">{code}</span>

				<span className="work__name">{title}</span>
			</div>

			<WorkDescription description={description} />

			<WorkAward award={award} />

			<WorkLinks links={workLinks} />
		</div>
	);
};

const WorkYear = ({ year }) => <div className="works__year">{year}</div>;

function Works() {
	let [ works, setWorks ] = useState();
	const [ workDesc, setWorkDesc ] = useState();

	useEffect(() => {
		let elements = [];

		WorksJSON.data.forEach((period) => {
			elements.push(<WorkYear year={period.year} key={period.year} />);

			period.works.forEach((work) => {
				elements.push(
					<Work
						code={work.code}
						title={work.title}
						description={work.description}
						award={work.award}
						links={work.links}
						key={work.code}
					/>
				);
			});
		});

		setWorks(elements);
	}, []);

	useEffect(() => {
		fetch(WorksMarkdown).then((res) => res.text()).then((text) => setWorkDesc(text));
	});

	return (
		<section className="works" id="works">
			<header className="works__title">Works</header>
			<article className="works__details">
				<ReactMarkdown source={workDesc} />
			</article>

			<div className="works__content">{works}</div>
		</section>
	);
}

export default Works;
