import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-scroll';
import { FaBars, FaTimes } from 'react-icons/fa';
import classNames from 'classnames/bind';

const NavBrand = () => <div className="nav__brand">Abraham Glasser</div>;

const NavLink = ({ link, onClick }) => (
	<li className="nav__link">
		<Link activeClass="active" to={link.uri} spy={true} smooth={true} duration={200} offset={-80} onClick={onClick}>
			{link.name}
		</Link>
	</li>
);

const NavCV = () => (
	<div className="nav__cv">
		<a href="pdfs/Glasser_Abraham_CV.pdf" target="__blank" rel="noopener noreferrer">
			Curriculum Vitae
		</a>
	</div>
);

function Header() {
	const [ scroll, setScroll ] = useState(false);
	const [ menuOpen, setMenuOpen ] = useState(false);
	const navData = [
		{
			name: 'About Me',
			uri: 'about'
		},
		{
			name: 'Works',
			uri: 'works'
		}
	];
	const links = navData.map((link) => <NavLink onClick={() => setMenuOpen(false)} link={link} key={link.name} />);
	const listenToScroll = useCallback(() => {
		if (document.scrollingElement.scrollTop > 10) {
			setScroll(true);
		} else {
			setScroll(false);
		}
	}, []);
	const headerStyles = classNames('header', { 'header--scroll': scroll });
	const navMenuStyles = classNames('nav__menu', { 'nav__menu--open': menuOpen });
	const coverStyles = classNames('cover', { 'cover--show': menuOpen });

	useEffect(
		() => {
			window.addEventListener('scroll', listenToScroll);

			return () => window.removeEventListener('scroll', listenToScroll);
		},
		[ listenToScroll ]
	);

	return (
		<header className={headerStyles}>
			<nav className="nav">
				<NavBrand />

				<div className="nav__expand">
					{!menuOpen && <FaBars onClick={() => setMenuOpen(true)} />}
					{menuOpen && <FaTimes onClick={() => setMenuOpen(false)} />}
				</div>

				<div className={navMenuStyles}>
					<ul className="nav__list">{links}</ul>
					<NavCV />
				</div>

				<div className={coverStyles} onClick={() => setMenuOpen(false)} />
			</nav>
		</header>
	);
}

export default Header;
