import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import AboutMarkdown from '../markdowns/About.md';

function About() {
	const [ about, setAbout ] = useState();

	useEffect(() => {
		fetch(AboutMarkdown).then((res) => res.text()).then((text) => setAbout(text));
	});

	return (
		<section className="about" id="about">
			<header className="about__title">
				About Me
			</header>

			<div className="about__content">
				<article className="about__me">
					<ReactMarkdown source={about} />
				</article>

				<div
					className="about__picture"
					role="img"
					alt="Picture of Abraham Glasser smiling in a dress shirt with a neck tie"
				/>
			</div>

			<a className="about__contact" href="mailto:abraham.glasser@gmail.com">
				Get in Touch
			</a>
		</section>
	);
}

export default About;
