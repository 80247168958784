import React from 'react';

import Header from './components/Header';
import About from './components/About';
import Works from './components/Works';

function App() {
	return (
		<div className="app">
			<Header />
			<About />
			<Works />
		</div>
	);
}

export default App;
